<template>
  <div>
    <Head :title="type==1?$t('vipRanking.head1'):$t('vipRanking.head2')"></Head>
    <div class="center" style="padding: 0 20px;" :style="'width:'+$setWidth">
      <div class="LP_title" style="padding-bottom:0;">{{$t('vipRanking.title1')}}</div>
      <!-- 等级 -->
      <div class="LP_box1 flex_lr">
        <div class="flex_row">
          <img src="@/image/yes.png" class="LP_icon" alt="">
          <div class="LP_text flex_row">
            <div>{{$t('vipRanking.text1')}}V{{level}}</div>
            <img :src="require('@/image/vip/V' + level+'.png')" style="width:20px;height:20px;margin-left:10px;" alt="">
          </div>
        </div>
        <!-- <div class="LP_btn2">激活</div> -->
      </div>
      <!--  -->
      <div class="LP_box1 flex_row">
        <img src="@/image/yes.png" class="LP_icon" v-if="upLevel[level].team<=info.powerTeam" alt="">
        <img src="@/image/no.png" class="LP_icon" v-else alt="">
        <div class="LP_text">{{$t('vipRanking.text2')}}{{info.powerTeam}}</div>
      </div>
      <!-- 表 -->
      <div class="LP_title" style="color:#ffffff;font-size:1rem;">{{type==1?$t('vipRanking.title2'):$t('vipRanking.title3')}}</div>
      <el-table :data="tableData" :header-cell-style="{background:'#0C2379',color:'#ffffff'}" style="width: 100%;overflow:auto;margin-top:10px;" cell-class-name="success-row" row-class-name="table_row" >
        <el-table-column type="index" align="center" width="50" :label="$t('vipRanking.text3')" />
        <!-- <el-table-column prop="lp" align="center" :label="'Farmer等级'" /> -->
        <el-table-column prop="address" align="center" :label="$t('vipRanking.text4')">
          <template slot-scope="scope">
            {{ scope.row.add.slice(0,3)+'*********'+scope.row.add.slice(scope.row.add.length-1) }}
          </template>
        </el-table-column>
        <el-table-column prop="count" align="center" :label="'当前社区算力'">
          <template slot-scope="scope">
            {{ type==1?(tableData.length-scope.$index>7?'*****':scope.row.count):(tableData.length-scope.$index>3?'*****':scope.row.count) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height: 20px;"></div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
export default {
  data () {
    return {
      type:1,
      info:{},
      user:{},
      level:0,
      addPop2:false,
      tableData:[],
      last_page:1,
      current_page:1,
      is_next:false,
    }
  },
  computed: {
    upLevel(){
      return this.$store.state.user.levelconfig
    }
  },
  async mounted () {
    await this.$onLaunched;
    if(this.upLevel.length==0){
      walletHelper.getLevelconfig()
    }
    this.type = this.$route.query.type
    this.init()
    // this.getList()
  },
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    getList(){
      if(this.is_next)return
      this.is_next = true
      this.get('', {field:this.nType,page:this.current_page}, 'POST').then(res => {
        console.log(res.list.data)
        this.is_next = false
        this.last_page = res.list.last_page;//分页总数
        this.current_page = res.list.current_page;//当前页
        if (this.current_page==1) {
          this.tableData = res.list.data
        }else {
          if(res.list.data.length>0){
            this.tableData = this.tableData.concat(res.list.data)
          }
        }
      })
    },
    init() {
      const that=this
      walletHelper.getContract('rel').methods.getPressureNftTokenId(walletHelper.getAddress()).call().then(res=>{
        console.log(res)
        that.$set(that.info,'pressureNftTokenId',res>0?res:'无')
        if(that.info.pressureNftTokenId>0){
          walletHelper.getContract('daoV1').methods.getBranchCount(walletHelper.getAddress()).call().then((result)=>{
            that.$set(that.info,'mt10000',walletHelper.Wei(result[0]))
            that.$set(that.info,'ulm10000',walletHelper.Wei(result[1]))
            if(result[2]>0||result[3]>0){
              that.$set(that.info,'mtV',walletHelper.Wei(result[2]))
              that.$set(that.info,'ulmV',walletHelper.Wei(result[3]))
            }
          })
        }
      })

      walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
        if(res){
          that.user=res
          // if(Number(res.parentReward)>0){
          //   walletHelper.getContract('query').methods.getUsdtToToken(walletHelper.getContractAddress('mt'),res.parentReward).call().then(res=>{
          //     that.$set(that.user,'parentReward',walletHelper.Wei(res))
          //   })
          // }
            if(Number(res.power)>0||res._minerCount_390>0){
              walletHelper.getContract('daoV1h').methods.getTeamPower(walletHelper.getAddress()).call().then(res=>{
                console.log(res)
                that.$set(that.info,'powerTeam',res)

              })
            }
            that.level=res.userLevel

          }else{
            console.log('getUserData错误  error',e)
          
        }
      })
      walletHelper.getContract('daoV1').methods.getChildMintCount(walletHelper.getAddress()).call().then((result)=>{
        that.$set(that.info,'childCount',result)
      })


      walletHelper.getContract('daoV1').methods.getChildMintCount(walletHelper.getAddress()).call().then((result)=>{
        that.$set(that.info,'childCount',result)
      })
      walletHelper.getContract('daoV1').methods.vipRanking(String(this.type-1)).call().then((result)=>{
        console.log(result)
        let n=0
        let data=[]
        for(let i in result){
          walletHelper.getContract('daoV1h').methods.getTeamPower(String(result[i])).call().then((e)=>{
            console.log(i,result[i],e)

            data.push({add:result[i],count:e})
            n++
            if(n>=result.length){
              for(let j=0;j<data.length;j++){
                for(let k=j+1;k<data.length;k++){
                  if(Number(data[j].count)<Number(data[k].count)){
                    let obj=data[j]
                    data[j]=data[k]
                    data[k]=obj
                    
                  }
                }
              }
              that.tableData=data
            }
          })
        }
        
      })


    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
  }
}
</script>
 
<style lang="scss">
// 图表
.el-table {
  font-family: simsong;
  color: #ffffff !important;
  background: #041123;
  font-size: 0.76rem !important;
}
.el-table__header-wrapper{
  border-radius: 5px !important;
  overflow: hidden;
}
.el-table__body tr:hover > td {
  background-color: transparent !important;
}
.el-table--border .el-table__cell {
  border: none;
}
.el-table tr:nth-of-type(1n+0) {
  background: #020b1b;
}
.el-table tr:nth-of-type(2n+0) {
  background: #081850;
}
.el-table td{
  border: none !important;
}
.el-table th{
  border-bottom: none !important;
}
.el-table::before{
  width: 0 !important;
}
</style>
<style lang="scss" scoped>
  .LP_icon{
    width: 15px;
    margin-right: 10px;
  }
  .LP_title{
    color: #00FFEA;
    font-size: 1.1rem;
    padding: 10px 0;
  }
  .LP_subtitle{
    font-size: 0.95rem;
    font-family: Microsoft YaHei;
    color: #00FFEA;
    padding: 10px 0;
    i{
      margin-left: 5px;
      font-size: 0.95rem;
    }
  }
  .LP_title2{
    color: #ffffff;
    font-size: 1rem;
    padding-bottom: 10px;
  }
  .LP_text{
    color: #ffffff;
    font-size: 0.8rem;
    padding: 8px 0;
  }
  .LP_box1{
    width: 100%;
    background: #0C2379;
    border-radius: 8px;
    padding: 10px 13px;
    margin: 15px 0;
  }
  .LP_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 5px 13px;
    color: #ffffff;
  }
  .LP_btn3{
    width: 100%;
    background: #00FFEA;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
  }
  .LP_btn{
    text-align: center;
    border-radius: 5px;
    color: #05faeb;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 3px 18px;
    border: 1px solid #05faeb;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #05faeb;
    border-radius: 5px;
    color: #000;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 5px 8px;
    white-space: nowrap;
  }
</style>